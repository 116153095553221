/* Container styling */
.slider-container {
    width: 150px;
    text-align: center;
}

.slider-container span {
    font-size: 13px;
}


/* Progress bar */
.progress-bar {
    width: 100%;
    height: 6px;
    background-color: #d1cfcf;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 4px;
}

.progress {
    height: 100%;
    background-color: black;
    width: 10%;
    transition: width 0.3s ease;
}