.MuiPaper-root.MuiPaper-elevation.EducationPaper {
    /* width: 50%; */
    padding: 20px;
    margin-right: 0px;
    height: fit-content;
}

.education-heading {
    font-size: 17px;
    letter-spacing: 0.5px;
    margin-bottom: 15px;
}

.education-form {
    width: 100%;
    padding: 20px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
}

.education-form-1 {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 10px;
}

@media screen and (max-width: 450px) {
    .education-form {
        grid-template-columns: 1fr;

    }
}

@media screen and (min-width: 1010px) and (max-width: 1300px) {
    .MuiPaper-root.MuiPaper-elevation.EducationPaper {
        width: 90%;
        padding: 40px;

    }
}

@media screen and (max-width: 1010px) {
    .MuiPaper-root.MuiPaper-elevation.EducationPaper {
        width: 100%;
        padding: 20px;
    }
}

.EducationPaper {
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}