  .resume-container-7 {
      max-width: 650px;
      height: 800px;
      /* margin: 40px auto; */
      overflow-y: scroll;
      background-color: #fff;
      padding: 20px 0px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .resume-title-7 {
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 10px;
  }

  .resume-summary-7 {
      text-align: center;
      font-size: 0.95rem;
      color: #555;
      margin-bottom: 20px;
  }

  .personal-info-7 {
      display: flex;
      background-color: #f0f0f0;
  }

  .exprience-info-7 {
      display: flex;
  }

  .section-title-7 {
      padding: 10px;
      font-size: 17px;
      font-weight: bold;
      border-radius: 4px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      /* text-align: center; */
      width: 25%
  }

  .section-content-7 {
      padding-left: 20px;
      margin: 20px 0px;
      line-height: 20px;
      width: 75%;
      padding: 0px 10px;
  }

  .section-content-7 strong {
      margin-left: 6px;
  }

  .exprience-7-card {
      line-height: 18px
  }

  .exprience-7-card h5 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0px;
  }

  .exprience-7-card p:first-of-type {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
      padding: 0;
  }

  .exprience-7-card span {
      font-size: 14px;
      font-weight: lighter;
  }

  .exprience-7-card p:nth-of-type(2) {
      font-size: 16px;
      font-weight: lighter;
  }

  .company-info-7 {
      font-style: italic;
      margin: 5px 0;
  }

  .job-title-7 {
      font-weight: bold;
      margin-top: 15px;
  }

  .job-duties-7 {
      list-style-type: disc;
      padding-left: 20px;
  }

  .resume-link-7 {
      text-decoration: none;
  }

  .resume-link-7:hover {
      text-decoration: underline;
  }

  .personal-info-7 p {
      margin: 4px 0;
  }


  .Habilidades-7-card h5 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0px;
  }

  .resume-7-job-desc-skill {
      font-size: 14px;
      list-style: disc;
      color: #333;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      padding: 0;
      line-height: 1.5;
  }

  .resume-7-job-desc-skill li {
      width: calc(30% - 10px);
  }