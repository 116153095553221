.key-skills-form-fields {
    width: 100%;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    margin-bottom: 20px;
}

.key-skills-form-fields .key-input-with-delete {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.key-skills-form-fields .key-input-with-delete .MuiSvgIcon-root:hover {
    cursor: pointer;
}

.MuiPaper-root.MuiPaper-elevation.keySkillPaper {
    /* width: 50%; */
    padding: 40px;
    margin-right: 0px;
    height: fit-content;
}

.MuiPaper-root.MuiPaper-elevation.keySkillPaper.key-skills-heading {
    font-size: 17px;
    letter-spacing: 0.5px;
    margin-bottom: 15px;
    /* font-family: "Poppins", sans-serif; */
}

.key-skills-heading {
    font-size: 17px;
}

.MuiButtonBase-root.MuiButton-root.add-new-btn {
    text-transform: none;
    margin-bottom: 30px;
    color: #951dc1;
}

.MuiDivider-root.key-skills-divider {
    border-bottom-width: 2px;
    margin: 20px 0px;
}

@media screen and (max-width: 1010px) {
    .MuiPaper-root.MuiPaper-elevation.keySkillPaper {
        width: 100%;
        padding: 20px;

    }
}

@media screen and (min-width: 1010px) and (max-width: 1300px) {
    .MuiPaper-root.MuiPaper-elevation.keySkillPaper {
        width: 90%;
        padding: 40px;

    }
}

@media screen and (max-width: 450px) {
    .key-skills-form-fields {
        grid-template-columns: 1fr;

    }
}


.keySkillPaper {
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}