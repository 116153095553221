.resume-container-5 {
    /* background: white;
    max-width: 700px;
    min-height: 800px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */

    max-width: 650px;
    /* min-height: 800px; */
    height: 800px;
    overflow-y: scroll;
    /* margin: 20px auto; */
    padding: 30px;
    border: 1px solid #ddd;
    background: #fff;
    color: #000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
}

.resume-header-5 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.resume-contact-info-5 {
    color: gray;
    /* margin-bottom: 30px; */
    display: grid;
    justify-content: end;
    margin: 15px 0px;

}

.resume-contact-info-5 p {
    padding: 0px;
    margin: 0px
}

.resume-content-5 div {
    line-height: 21px;
}

.resume-5-line {
    border-bottom: 1px solid black;
    margin: 0px 10px
}