.MuiPaper-root.MuiPaper-elevation.work-experience-paper {
  /* width: 50%; */
  padding: 25px;
  margin-right: 0px;
  height: fit-content;
}

.workExperienceHeading {
  font-size: 17px;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
}

.add-new-btn-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #951dc1
}

.experience-cont {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 10px;
}

.experience-heading {
  font-size: 14px;
}

.experience-form-cont {
  width: 100%;
  padding: 20px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1010px) {
  .MuiPaper-root.MuiPaper-elevation.work-experience-paper {
    width: 100%;
    padding: 30px;
  }
}

@media screen and (min-width: 1010px) and (max-width: 1300px) {
  .MuiPaper-root.MuiPaper-elevation.work-experience-paper {
    width: 90%;
    padding: 40px;
  }
}

@media screen and (max-width: 450px) {
  .education-form-cont {
    grid-template-columns: 1fr;
  }

  .experience-form-cont {
    grid-template-columns: 1fr;
  }
}

  .work-experience-paper{
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }