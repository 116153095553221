.resume-6-container {
    max-width: 650px;
    /* min-height: 800px; */
    height: 800px;
    overflow-y: scroll;
    /* margin: 20px auto; */
    padding: 30px;
    border: 1px solid #ddd;
    background: #fff;
    color: #000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
}

/* Header */
.resume-6-header {
    text-align: center;
    margin-bottom: 20px;
}

.resume-6-name {
    font-size: 28px;
    font-weight: bold;
    /* color: black; */
}

.resume-6-name-red {
    font-size: 28px;
    font-weight: bold;
    color: rgb(150 30 39);
}



.resume-6-subtext {
    font-size: 14px;
    color: #000;
    margin: 3px 0;
}

.resume-6-subtext span {
    font-weight: bold;
    color: rgb(150 30 39);
}

/* Section Title */
.resume-6-section-title {
    font-size: 20px;
    font-weight: bold;
    color: #000;
    /* border-bottom: 2px solid gray;
    margin-bottom: 10px;
    padding-bottom: 5px; */
}


.resume-6-line {
    border: none;
    border-top: 2px solid gray;
    flex-grow: 1;
    margin-left: 10px;
    align-self: center;
}

/* Profile */
.resume-6-profile {
    font-size: 14px;
    color: #333;
    text-align: justify;
}

/* Work Experience */
.resume-6-job {
    margin-bottom: 20px;
}

.resume-6-job-title {
    font-size: 16px;
    font-weight: bold;
}

.resume-6-company {
    font-size: 15px;
    font-weight: 400;
    color: #000;
}

.resume-6-date-location {
    font-size: 13px;
    /* color: #555; */
    color: rgb(150 30 39);
    margin-bottom: 5px;
}

.resume-6-job-desc {
    font-size: 14px;
    margin-left: 20px;
    list-style: disc;
    color: #333;
}

.resume-6-job-desc-skill {
    font-size: 14px;
    margin-left: 20px;
    list-style: disc;
    color: #333;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0;
    line-height: 1.5;
}

.resume-6-job-desc-skill li {
    width: calc(20% - 10px);
}



.resume-6-job-desc-hobbies {
    font-size: 14px;
    margin-left: 20px;
    list-style: disc;
    color: #333;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0;
    line-height: 1.5;
}

.resume-6-job-desc-hobbies li {
    width: calc(20% - 10px);
}

/* Education */
.resume-6-education-title {
    font-size: 16px;
    font-weight: bold;
}

.resume-6-education-desc {
    font-size: 14px;
    color: #555;
}

/* Skills */
.resume-6-skills {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 10px;
}

.resume-6-skill {
    font-size: 14px;
    color: #333;
}

/* Hobbies */
.resume-6-hobbies {
    display: flex;
    gap: 15px;
    margin-top: 10px;
}

.resume-6-hobby {
    font-size: 14px;
    color: #333;
}

/* Links */
.resume-6-subtext a {
    color: #0000ee;
    text-decoration: none;
}

.resume-6-subtext a:hover {
    text-decoration: underline;
}