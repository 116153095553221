.MuiPaper-root.MuiPaper-elevation.personal-info-paper {
    /* width: 50%; */
    padding: 40px;
    /* margin-left: 100px; */
    height: fit-content;
}

.MuiButtonBase-root.MuiButton-root.change-profile-photo-btn {
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 15px;
    color: #951dc1;
    border: 1px solid #951dc1;
}


.personal-Info-form-fields {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 20px;
    margin-bottom: 20px;
}


@media screen and (max-width: 450px) {
    .personal-Info-form-fields {
        grid-template-columns: 1fr;

    }
}



.MuiDivider-root.personal-details-divider {
    border-bottom-width: 2px;
    margin: 20px 0px;
}



@media screen and (max-width: 1010px) {
    .MuiPaper-root.MuiPaper-elevation.personal-info-paper {
        width: 100%;
        padding: 20px;

    }
}

@media screen and (min-width: 1010px) and (max-width: 1300px) {
    .MuiPaper-root.MuiPaper-elevation.personal-info-paper {
        width: 100%;
        padding: 15px;

    }
}

.resumePhoneInput {
    width: 100% !important;
    height: 45px !important;
}

.personal-info-paper {
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}