.rctb-div {
    max-width: 100%;
    /* margin: 0 auto; */
    padding: 16px;
}

.rctb-first-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.rctb-first-div h2 {
    font-size: 1.5rem;
    font-weight: 700;
}

.rctb-first-div button {
    background-color: #6f21b7;
    color: #ffffff;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.rctb-close-div button {
    color: #6f21b7;
    border: 1px solid #6f21b7;
    padding: 7px 12px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: #000;
}

.active-type-resume {
    font-size: 1rem;
    font-weight: 600;
    color: #000;
    padding-bottom: 4px;
    border-bottom: 2px solid #6f21b7;
    border-color: #6f21b7;
    margin-right: 16px;
    cursor: pointer;
}

.deactive-type-resume {
    font-size: 1rem;
    font-weight: 600;
    color: #9ca3af;
    padding-bottom: 4px;
    margin-right: 16px;
    cursor: pointer
}


.cplx-row {
    background-color: rgba(241, 241, 241, 0.31);
    display: flex;
    flex-wrap: wrap;
}

.cplx-resume-card {
    display: flex;
    justify-content: space-between;
    padding: 25px 15px;
}

.cplx-resume-card-container {
    display: flex;
    border-radius: 10px;
    padding: 15px;
    width: 450px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.cplx-resume-card-image {
    width: 200px;
    height: 300px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    display: flex;
}

.cplx-resume-card-name {
    font-size: 12px;
    color: #333;
    font-weight: bold;
}

.cplx-resume-card-content {
    flex: 1;
    margin-left: 20px;
}

.cplx-resume-card-title {
    margin: 0 0 5px 0;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: center;
}

.cplx-resume-card-updated {
    font-size: 12px;
    color: #777;
}

.cplx-resume-card-actions {
    margin-top: 10px;
}

.cplx-resume-card-action-item {
    color: rgb(30, 37, 50);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: center;
}

.cplx-resume-card-new {
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
}

.cplx-resume-card-new-box {
    width: 350px;
    height: 300px;
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.cplx-resume-card-add-btn {
    width: 50px;
    height: 50px;
    background-color: #f5f7fb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    color: #c0c4d6;
}

.cplx-resume-card-new-text {
    margin-left: 20px;
}

.cplx-resume-card-new-title {
    margin: 0;
    color: #5a6473;
    font-size: 20px;
    font-weight: 500;
}

.cplx-resume-card-new-description {
    margin: 10px 0 0;
    color: #99a0aa;
    font-size: 14px;
    line-height: 1.5;
}


@media (max-width: 480px) {
    .cplx-resume-card {
        width: 100%;
        padding: 10px;
    }

    .cplx-resume-card-container {
        /* width: 95%; */
        /* flex-direction: column; */
        align-items: center;
    }

    .cplx-resume-card-image {
        width: 145px;
        height: 210px;
    }

    .cplx-resume-card-title {
        font-size: 14px;
    }

    .cplx-resume-card-updated {
        font-size: 10px;
    }

    .cplx-resume-card-actions {
        flex-direction: column;
        align-items: center;
    }

    .cplx-resume-card-action-item {
        font-size: 12px;
    }

    .cplx-resume-card-new {
        /* flex-direction: column; */
        align-items: center;
        width: 100%;
    }

    .cplx-resume-card-new-box {
        width: 250px;
        height: 200px;
    }

    .cplx-resume-card-new-text {
        text-align: center;
    }

    .cplx-resume-card-new-title {
        font-size: 18px;
    }

    .cplx-resume-card-new-description {
        font-size: 12px;
    }

    .rctb-first-div h2 {
        font-size: 1rem;
    }

    .rctb-first-div button {
        padding: 11px 5px;
        font-size: 13px;
    }
}

@media screen and (min-width: 320px) {
    .css-pqlswy {
        width: 100% !important;
        display: flex;
        justify-content: space-evenly;
    }
}