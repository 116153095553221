body {
  background-color: white;
}

.css-7ljumx {
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 10px 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.pagination li {
  display: inline-block;
  margin: 0 5px;
}

.pagination a {
  color: #422afb !important;
  text-decoration: none;
  padding: 6px 12px;
  border: 1px solid rgba(134, 103, 245, 0.363);
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-weight: 400;
}

.pagination a:hover {
  background-color: #e6e2ff !important;
  color: #422afb !important;
}

.pagination .selected a {
  background-color: #422afb !important;
  color: #fff !important;
}

.pagination .disabled a {
  pointer-events: none;
  background-color: #eee !important;
  color: #aaa !important;
  border: 1px solid #aaa;
}

.tooltip_style {
  position: relative;
  z-index: 1000;
  width: 400px !important;
  border-radius: 10px !important;
  background-color: #6d1eb6 !important;
  color: #ffffff !important;
  border: solid 1px rgb(219, 219, 219);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  /* Adding shadow for elevation */
}

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb */
  border-radius: 6px;
  border: .8px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* For Firefox */
* {
  scrollbar-width: thin; /* auto | thin | none */
  scrollbar-color: #888 #f1f1f1; /* Thumb color | Track color */
}
