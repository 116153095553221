/* App container styling */
.app-swiper-template {
    text-align: center;
    padding: 30px 20px;
    background-color: #8d41ab;
}

.app-swiper-template h2 {
    font-size: 26px;
    color: whitesmoke;
    font-weight: 500;
}

/* Swiper container styling */
.mySwiper-swiper-template {
    width: 75%;
    height: 600px;
    margin: 20px auto;
}

/* Swiper slide styling */
.swiper-slide img {
    display: block;
    width: 100%;
    height: 600px;
    border-radius: 10px;
    /* box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); */
    position: relative;
}

.swiper-slide-custom {}


.custom-active-button {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #951dc1;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 18px;
    font-weight: lighter;
    line-height: 22px;
    outline: none;
    padding: 12px 24px;
    text-align: center;
    transition: background-color .1s, color .1s, box-shadow .1s;

}

.active-slide .custom-active-button {
    opacity: 1;
}

.custom-active-button:hover {
    background-color: #861cad;
}


.mySwiper-swiper-template .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.mySwiper-swiper-template .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: -20px var(--swiper-pagination-bullet-horizontal-gap, 4px);
    background-color: #951dc1;
    visibility: hidden;
}

/* -------------------btn */

.mySwiper-swiper-template.swiper-button-prev {
    width: 45px;
    height: 45px;
    background-color: #951dc170;
    color: white;
    border-radius: 50%;
    transition: all 0.5s;
    margin-left: -10px;
}

.mySwiper-swiper-template.swiper-button-prev:hover {
    background-color: #951dc1;
    border: solid 2px #861cad;
    transition: all 0.5s;
}

.mySwiper-swiper-template.swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background-color: #951dc170;
    color: white;
    border-radius: 50%;
    transition: all 0.5s;
    margin-right: 12px;
}

.mySwiper-swiper-template.swiper-button-next:hover {
    background-color: #951dc1;
    border: solid 2px #372d3a;
    transition: all 0.5s;
}

.mySwiper-swiper-template.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    content: 'prev';
    font-size: 2px;
    font-weight: 600;
}

.mySwiper-swiper-template.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 18px;
    font-weight: 600;
}


@media (max-width: 1200px) {}

@media (max-width: 768px) {}

@media (max-width: 480px) {
    .app-swiper-template {
        text-align: center;
        padding: 20px 0px;
    }

    .mySwiper-swiper-template {
        width: 75%;
        height: auto;
        margin: 20px auto;
    }

    .custom-active-button {
        position: absolute;
        bottom: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #951dc1;
        border-radius: 4px;
        color: white;
        cursor: pointer;
        font-size: 18px;
        font-weight: lighter;
        line-height: 22px;
        outline: none;
        padding: 10px 8px;
        text-align: center;
        transition: background-color .1s, color .1s, box-shadow .1s;

    }
}